import { body, face, faceOrBody, female } from '@/modules/questionnaire/api/helpers';

export default {
  lifestyle1: {
    // P(40)
    index: 40,
    heading: 'heading.mySkinAndLifestyle',
    previous({ gender, age }) {
      return female(gender) && age > 16
        ? 'medicalBackground/skinSensitivity'
        : 'medicalBackground/medicalHistory';
    },
    next: 'oxidativeStress/lifestyle2'
  },

  lifestyle2: {
    // P(41)
    index: 41,
    heading: 'heading.mySkinAndLifestyle',
    previous: 'oxidativeStress/lifestyle1',
    next: 'oxidativeStress/environment'
  },

  environment: {
    // P(42)
    index: 42,
    heading: 'heading.mySkinAndEnvironment',
    previous: 'oxidativeStress/lifestyle2',
    next: 'oxidativeStress/sun'
  },

  sun: {
    // P(43)
    index: 43,
    heading: 'heading.mySkinAndSun',
    previous: 'oxidativeStress/environment',
    next: 'oxidativeStress/relationshipWithSun'
  },

  relationshipWithSun: {
    // P(44)
    index: 44,
    heading: 'heading.myRelationshipWithSun',
    previous: 'oxidativeStress/sun',
    next: 'oxidativeStress/nutrition'
  },

  nutrition: {
    // P(45)
    index: 45,
    heading: 'heading.mySkinAndNutrition',
    previous: 'oxidativeStress/relationshipWithSun',
    next({ soughtTreatments, age }) {
      if (!faceOrBody(soughtTreatments)) {
        return undefined;
      }
      if (age >= 25) {
        if (body(soughtTreatments)) {
          return 'skinAppearance/thinningLocations';
        }
        if (face(soughtTreatments)) {
          return 'skinAppearance/thinning';
        }

        return 'skinAppearance/drying';
      }

      return 'skinAppearance/darkCircles';
    }
  }
};
