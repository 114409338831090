import {
  includes,
  includesAny,
  equalsAny,
  yes,
  no,
  any,
  face,
  eyeContour,
  body,
  faceOrBody,
  onlyEyeContour,
  onlyBody,
  onlyFace,
  getLocalizedScarCause
} from '@/modules/questionnaire/api/helpers';

import { getLocalizedScarTypes, getScarCauses } from '@/modules/questionnaire/utils';

import {
  SAGGING_SKIN_LOCATION,
  ACNE_SCAR_CAUSE,
  FACE_WRINKLES,
  EYE_WRINKLES
} from '@/modules/questionnaire/api/constants';

export default {
  thinning: {
    // P(46)
    index: 47,
    heading: 'heading.mySkinAndAgeing',
    previous: 'oxidativeStress/nutrition',
    next: 'skinAppearance/sagging',
    visible({ age, soughtTreatments }) {
      return age >= 25 && face(soughtTreatments) && !body(soughtTreatments);
    }
  },

  thinningLocations: {
    // P(47)
    index: 48,
    heading: 'heading.mySkinAndAgeing',
    previous: 'skinAppearance/title',
    next: 'skinAppearance/saggingLocations',
    visible({ age, soughtTreatments }) {
      return age >= 25 && body(soughtTreatments);
    }
  },

  sagging: {
    // P(48)
    index: 49,
    heading: 'heading.mySkinAndAgeing',
    previous: 'skinAppearance/thinning',
    next({ saggingSkin }) {
      return yes(saggingSkin) ? 'skinAppearance/faceSagging' : 'skinAppearance/drying';
    },
    visible({ age, soughtTreatments }) {
      return age >= 25 && face(soughtTreatments) && !body(soughtTreatments);
    }
  },

  saggingLocations: {
    // P(49)
    index: 50,
    heading: 'heading.mySkinAndAgeing',
    previous: 'skinAppearance/sagging',
    next({ saggingSkinLocations }) {
      return includes(saggingSkinLocations, SAGGING_SKIN_LOCATION.FACE_AND_NECK)
        ? 'skinAppearance/faceSagging'
        : 'skinAppearance/drying';
    },
    visible({ age, soughtTreatments }) {
      return age >= 25 && body(soughtTreatments);
    }
  },

  faceSagging: {
    // P(50)
    index: 51,
    heading: 'heading.mySkinAndAgeing',
    previous({ saggingSkin }) {
      return saggingSkin ? 'skinAppearance/sagging' : 'skinAppearance/saggingLocations';
    },
    next: 'skinAppearance/drying',
    visible({ saggingSkin, saggingSkinLocations }) {
      return (
        yes(saggingSkin) || includes(saggingSkinLocations, SAGGING_SKIN_LOCATION.FACE_AND_NECK)
      );
    }
  },

  drying: {
    // P(51)
    index: 52,
    heading: 'heading.mySkinAndAgeing',
    previous({ saggingSkin, saggingSkinLocations }) {
      // 107, 108
      if (saggingSkin) {
        return yes(saggingSkin) ? 'skinAppearance/faceSagging' : 'skinAppearance/sagging';
      }
      if (saggingSkinLocations) {
        return includes(saggingSkinLocations, SAGGING_SKIN_LOCATION.FACE_AND_NECK)
          ? 'skinAppearance/faceSagging'
          : 'skinAppearance/saggingLocations';
      }

      return 'oxidativeStress/nutrition';
    },
    next({ soughtTreatments, age }) {
      // 12, 5
      if (face(soughtTreatments)) {
        return 'skinAppearance/wrinkles';
      }
      if (onlyBody(soughtTreatments)) {
        return 'skinAppearance/cellulite';
      }
      if (age >= 35 && onlyEyeContour(soughtTreatments)) {
        return 'skinAppearance/wrinkles';
      }

      // should be unreachable (p 54 & 55 removed for now and face fixed)
      return 'skinAppearance/darkCircles';
    },
    visible({ age }) {
      return age >= 25;
    }
  },

  wrinkles: {
    // P(52)
    index: 53,
    heading: 'heading.mySkinAndWrinkles',
    previous: 'skinAppearance/drying',
    next({ wrinkles }) {
      const significantWrinkles = equalsAny(wrinkles, [
        FACE_WRINKLES.FACE_EXPRESSION_LINES,
        FACE_WRINKLES.FACE_DEEP_LINES,
        EYE_WRINKLES.EYE_EXPRESSION_LINES,
        EYE_WRINKLES.EYE_DEEP_LINES
      ]);

      if (significantWrinkles) {
        return 'skinAppearance/wrinkleLocations';
      }

      return 'skinAppearance/darkCircles';
    },
    visible({ age, soughtTreatments }) {
      return (
        (age >= 25 && face(soughtTreatments)) || (age >= 35 && onlyEyeContour(soughtTreatments))
      );
    }
  },

  wrinkleLocations: {
    // P(53)
    index: 54,
    heading: 'heading.mySkinAndWrinkles',
    previous: 'skinAppearance/wrinkles',
    next: 'skinAppearance/darkCircles',
    contentBaseWidth: 689,
    visible({ wrinkles }) {
      return equalsAny(wrinkles, [
        FACE_WRINKLES.FACE_EXPRESSION_LINES,
        FACE_WRINKLES.FACE_DEEP_LINES,
        EYE_WRINKLES.EYE_EXPRESSION_LINES,
        EYE_WRINKLES.EYE_DEEP_LINES
      ]);
    }
  },

  darkCircles: {
    index: 55,
    heading: 'heading.myEyeContour',
    previous({ age, soughtTreatments, wrinkles }) {
      const isFrom18To25AndFaceTreatment = age >= 18 && age < 25 && onlyFace(soughtTreatments);

      if ((age < 18 && !eyeContour(soughtTreatments)) || isFrom18To25AndFaceTreatment) {
        return 'oxidativeStress/nutrition';
      }

      const significantWrinkles = equalsAny(wrinkles, [
        FACE_WRINKLES.FACE_EXPRESSION_LINES,
        FACE_WRINKLES.FACE_DEEP_LINES,
        EYE_WRINKLES.EYE_EXPRESSION_LINES,
        EYE_WRINKLES.EYE_DEEP_LINES
      ]);

      return significantWrinkles ? 'skinAppearance/wrinkleLocations' : 'skinAppearance/wrinkles';
    },
    next: 'skinAppearance/bags'
  },

  bags: {
    index: 56,
    heading: 'heading.myEyeContour',
    previous: 'skinAppearance/darkCircles',
    next: 'skinAppearance/scars'
  },

  scars: {
    // P(58)
    index: 59,
    heading: 'heading.mySkinAndScars',
    previous: 'skinAppearance/bags',
    next({
      acneScars,
      acneScarCauses,
      thinningSkin,
      saggingSkin,
      dryingSkin,
      dryOpenPores,
      wrinkles,
      darkCircles,
      eyeBags,
      stretchMarks
    }) {
      if (no(acneScars)) {
        if (
          any(
            thinningSkin,
            saggingSkin,
            dryingSkin,
            dryOpenPores,
            wrinkles,
            darkCircles,
            eyeBags,
            stretchMarks
          )
        ) {
          return 'skinRedness/sensitivity';
        }

        return 'skinRedness/sensitivity';
      }
      if (includesAny(acneScarCauses, [ACNE_SCAR_CAUSE.ACNE, ACNE_SCAR_CAUSE.CHICKENPOX])) {
        return 'skinAppearance/acneScars';
      }

      return 'skinAppearance/otherScars';
    },
    visible({ soughtTreatment }) {
      return faceOrBody(soughtTreatment);
    }
  },

  acneScars: {
    // P(59)
    index: 60,
    heading: 'heading.myScars',
    headingFunc(i18n, { acneScarCauses }) {
      const causes = getScarCauses(acneScarCauses);

      return i18n.t('heading.myScars', [getLocalizedScarCause(i18n, causes)]);
    },
    previous: 'skinAppearance/scars',
    next({ acneScarCauses }) {
      return includesAny(acneScarCauses, [
        ACNE_SCAR_CAUSE.ACCIDENT,
        ACNE_SCAR_CAUSE.SURGERY,
        ACNE_SCAR_CAUSE.DISEASE,
        ACNE_SCAR_CAUSE.BURNS_AND_SCALDS
      ])
        ? 'skinAppearance/otherScars'
        : 'skinRedness/sensitivity';
    },
    visible({ acneScarCauses }) {
      return includesAny(acneScarCauses, [ACNE_SCAR_CAUSE.ACNE, ACNE_SCAR_CAUSE.CHICKENPOX]);
    }
  },

  otherScars: {
    // P(60)
    index: 61,
    heading: 'heading.myScars',
    headingFunc(i18n, { acneScarCauses }) {
      return getLocalizedScarTypes(i18n, acneScarCauses);
    },
    previous({ acneScarCauses }) {
      return includesAny(acneScarCauses, [ACNE_SCAR_CAUSE.ACNE, ACNE_SCAR_CAUSE.CHICKENPOX])
        ? 'skinAppearance/acneScars'
        : 'skinAppearance/scars';
    },
    next: 'skinRedness/sensitivity',
    visible({ acneScarCauses }) {
      return includesAny(acneScarCauses, [
        ACNE_SCAR_CAUSE.ACCIDENT,
        ACNE_SCAR_CAUSE.SURGERY,
        ACNE_SCAR_CAUSE.DISEASE,
        ACNE_SCAR_CAUSE.BURNS_AND_SCALDS
      ]);
    }
  }
};
