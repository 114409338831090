import { yes } from '@/modules/questionnaire/api/helpers';

export default {
  roughness: {
    index: 13,
    heading: 'heading.mySkinRoughness',
    previous({ greasySkin }) {
      return yes(greasySkin) ? 'skinOiliness/environment' : 'skinOiliness/oiliness';
    },
    next: 'skinTexture/bumps',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.grey;
      const { greyScorePhrase, greyText } = introTexts;

      const message = `${$t(greyScorePhrase, [score])} ${$t(greyText)}`;

      return { message, score };
    }
  },
  bumps: {
    index: 14,
    heading: 'heading.mySkinAndCongestion',
    previous: 'skinTexture/roughness',
    next: 'pimples/pimples',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.grey;
      const { greyScorePhrase, greyText } = introTexts;

      const message = `${$t(greyScorePhrase, [score])} ${$t(greyText)}`;

      return { message, score };
    }
  }
};
