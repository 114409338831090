export default {
  sun: {
    index: 3,
    heading: 'heading.mySkinAndSun',
    previous: 'photoAnalysisResults/improveYourResults',
    next: 'skinAppearance/ageing',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.yellow;
      const { yellowScorePhrase, yellowText } = introTexts;

      const message = `${$t(yellowScorePhrase, [score])} ${$t(yellowText)}`;

      return { message, score };
    }
  }
};
