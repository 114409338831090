import {
  all,
  any,
  includesAny,
  no,
  onlyEyeContour,
  yes
} from '@/modules/questionnaire/api/helpers';

import { ACNE_SCAR_CAUSE } from '@/modules/questionnaire/api/constants';

export default {
  sensitivity: {
    // P(62)
    index: 64,
    heading: 'heading.mySkinAndSensitivity',
    previous({ soughtTreatments, acneScars, acneScarCauses }) {
      if (onlyEyeContour(soughtTreatments)) {
        return 'skinAppearance/bags';
      }

      if (no(acneScars)) {
        return 'skinAppearance/scars';
      }

      const scars = includesAny(acneScarCauses, [
        ACNE_SCAR_CAUSE.ACCIDENT,
        ACNE_SCAR_CAUSE.SURGERY,
        ACNE_SCAR_CAUSE.DISEASE,
        ACNE_SCAR_CAUSE.BURNS_AND_SCALDS
      ]);

      return scars ? 'skinAppearance/otherScars' : 'skinAppearance/acneScars';
    },
    next: 'skinRedness/flushing'
  },

  flushing: {
    // P(63)
    index: 65,
    heading: 'heading.mySkinAndFlushing',
    previous: 'skinRedness/sensitivity',
    next({ flushes }) {
      return yes(flushes) ? 'skinRedness/flushingDetail' : 'skinRedness/redness';
    }
  },

  flushingDetail: {
    // P(64)
    index: 66,
    heading: 'heading.mySkinAndFlushing',
    previous: 'skinRedness/flushing',
    next: 'skinRedness/redness',
    visible({ flushes }) {
      return yes(flushes);
    }
  },

  redness: {
    // P(65)
    index: 67,
    heading: 'heading.mySkinAndRedness',
    previous({ flushes }) {
      return yes(flushes) ? 'skinRedness/flushingDetail' : 'skinRedness/flushing';
    },
    next({ skinRedness }) {
      return yes(skinRedness) ? 'skinRedness/rednessLocations' : 'skinRedness/capillaries';
    }
  },

  rednessLocations: {
    // P(66)
    index: 68,
    heading: 'heading.mySkinAndRedness',
    previous: 'skinRedness/redness',
    next: 'skinRedness/rednessDetail',
    visible({ skinRedness }) {
      return yes(skinRedness);
    }
  },

  rednessDetail: {
    // P(67)
    index: 69,
    heading: 'heading.mySkinAndRedness',
    previous: 'skinRedness/rednessLocations',
    next: 'skinRedness/capillaries',
    visible({ skinRedness }) {
      return yes(skinRedness);
    }
  },

  capillaries: {
    // P(68)
    index: 70,
    heading: 'heading.mySkinAndCapillaries',
    previous({ skinRedness }) {
      return yes(skinRedness) ? 'skinRedness/rednessDetail' : 'skinRedness/redness';
    },
    next({ prominentBloodVessels, flushes, skinRedness, sensitiveSkin }) {
      // 140, 131, 135, 130
      if (yes(prominentBloodVessels)) {
        return 'skinRedness/capillaryLocations';
      }
      if (all(flushes, skinRedness)) {
        return 'skinRedness/swelling';
      }
      if (any(sensitiveSkin, flushes, skinRedness)) {
        return 'skinDryness/dryness';
      }

      return 'skinDryness/dryness';
    }
  },

  capillaryLocations: {
    // P(69)
    index: 71,
    heading: 'heading.mySkinAndCapillaries',
    previous: 'skinRedness/capillaries',
    next({ flushes, skinRedness }) {
      return all(flushes, skinRedness) ? 'skinRedness/swelling' : 'skinDryness/dryness';
    },
    visible({ prominentBloodVessels }) {
      return yes(prominentBloodVessels);
    }
  },

  swelling: {
    // P(70)
    index: 72,
    heading: 'heading.mySkinAndSwelling',
    previous({ prominentBloodVessels }) {
      return yes(prominentBloodVessels)
        ? 'skinRedness/capillaryLocations'
        : 'skinRedness/capillaries';
    },
    next: 'skinDryness/dryness',
    visible({ flushes, skinRedness }) {
      return all(flushes, skinRedness);
    }
  }
};
