<template>
  <div>
    <questionnaire-page-template
      :title="currentStepTitle"
      :subtitle="currentStepSubtitle"
      :doctor-info="doctorInfo"
      :user-info="patientInfo"
      :language="patient.locale"
      :language-options="languageOptions"
      :is-next-loading="isLoading"
      :progress="progress"
      :time-left="timeLeft"
      :section-name="sectionName"
      :dysfunction="dysfunction"
      :language-select-disabled="isLanguageSelectDisabled"
      :is-final-step="isFinalStep"
      :previous-step-visible="isPreviousStepVisible"
      :next-step-visible="isNextStepVisible"
      :error-message="errorMessage"
      :skin-scoring="skinScoring"
      :no-footer="currentStepConfig.hideFooter"
      :content-base-width="contentBaseWidth"
      :basic-background="hasBasicBackground"
      :content-centered="contentCentered"
      :hide-section-title="isSectionTittleHidden"
      :has-why-we-ask-section="hasWhyWeAskSection"
      :no-divider="noDivider"
      @next="onNextStep"
      @previous="showPreviousStep"
      @change-language="onLanguageSelect"
    >
      <component
        :is="currentStepComponent"
        ref="stepComponent"
        @next="onNextStep"
        @previous="showPreviousStep"
        @valid="setStepValid(true)"
      />
    </questionnaire-page-template>

    <dialog-idle-modal @idle="onQuestionnaireIdle" />
  </div>
</template>

<script>
import { kebabCase, has, debounce } from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import { IdlePageTimer } from 'universkin-shared/src/utils/IdlePageTimer';

import DialogIdleModal from '@/modules/questionnaire/components/dialogs/DialogIdleModal.vue';
import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';

import { getQuestionnaireLocaleOptions } from '@/api/places';
import { pascalCase } from '@/modules/questionnaire/api/helpers';
import photoAnalysisConfig from '@/modules/questionnaire/api/config/photo-analysis';
import questionnaireConfig from '@/modules/questionnaire/api/config/questionnaire';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import {
  redirectPatientAfterQuestionnaire,
  clearLocalStorage
} from '@/modules/questionnaire/utils';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import rootTypes from '@/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import { QUESTIONNAIRE_TYPES } from '@/modules/questionnaire/api/constants';

const DEMOGRAPHIC_PAGE_ID = 'demographics';
const FINAL_SECTION_ID = 'identity';
const IDLE_TIMEOUT = process.env.NODE_ENV === 'production' ? 600000 : Infinity;
const NEXT_PAGE_DELAY = 400;

const TIME_TO_COMPLETE_THE_QUESTIONNAIRE_MINUTES_MAP = {
  [QUESTIONNAIRE_TYPES.STANDARD]: 10,
  [QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS]: 5
};

const ANSWER_ALL_QUESTIONS_ERROR_MESSAGE = 'info.warning.giveAnswerAllQuestions';

const QUESTIONNAIRE_DEFAULT_MODULE_DIRECTORY = {
  [QUESTIONNAIRE_TYPES.STANDARD]: 'questionnaire',
  [QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS]: 'photo-analysis'
};

const QUESTIONNAIRE_TYPES_CONFIG = {
  [QUESTIONNAIRE_TYPES.STANDARD]: questionnaireConfig,
  [QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS]: photoAnalysisConfig
};

export default {
  name: 'PageQuestionnaire',
  components: {
    DialogIdleModal,
    QuestionnairePageTemplate
  },
  mixins: [doctorAndPatientInfoMixin],
  data() {
    return {
      timer: null,
      isLoading: false,
      isStepValid: true
    };
  },
  computed: {
    ...mapState({
      questionnaireType: state => state.questionnaire.questionnaire.type,
      introTexts: state => state.questionnaire.introTexts,
      photoAnalysisDiagnostic: state => state.questionnaire.photoAnalysisDiagnostic
    }),
    ...mapGetters({
      doctor: questTypes.getters.GET_DOCTOR,
      questionnaire: questTypes.getters.GET_QUESTIONNAIRE,
      patient: questTypes.getters.GET_PATIENT,
      scores: questTypes.getters.GET_SCORES
    }),
    errorMessage() {
      return this.isStepValid ? '' : this.$t(ANSWER_ALL_QUESTIONS_ERROR_MESSAGE);
    },
    dysfunction() {
      return this.currentSectionConfig.dysfunction;
    },
    skinScoring() {
      const { getScoringConfig = () => null } = this.currentStepConfig;

      return getScoringConfig(this.$t, {
        introTexts: this.introTexts,
        photoAnalysisDiagnostic: this.photoAnalysisDiagnostic
      });
    },
    isPhotoAnalysis() {
      return !!this.photoAnalysisDiagnostic;
    },
    languageOptions() {
      return getQuestionnaireLocaleOptions();
    },
    context() {
      return this.questionnaire.context;
    },
    questionnaireFields() {
      return this.questionnaire.fields;
    },
    isLanguageSelectDisabled() {
      return this.currentStepId === DEMOGRAPHIC_PAGE_ID;
    },
    splitedCurrentStep() {
      return this.context.currentStep.split('/');
    },
    currentSectionId() {
      return this.splitedCurrentStep[0];
    },
    config() {
      return QUESTIONNAIRE_TYPES_CONFIG[this.questionnaireType];
    },
    currentSectionConfig() {
      return this.config[this.currentSectionId];
    },
    currentStepId() {
      return this.splitedCurrentStep[1];
    },
    currentStepConfig() {
      return this.currentSectionConfig.steps[this.currentStepId];
    },
    noDivider() {
      return this.currentStepConfig.noDivider;
    },
    contentBaseWidth() {
      return this.currentStepConfig.contentBaseWidth;
    },
    hasBasicBackground() {
      return this.currentStepConfig.basicBackground;
    },
    contentCentered() {
      return this.currentStepConfig.contentCentered;
    },
    currentStepTitle() {
      const { headingFunc, heading } = this.currentStepConfig;

      return headingFunc
        ? headingFunc(this.$i18n, this.questionnaireFields)
        : this.$i18n.t(heading);
    },
    isSectionTittleHidden() {
      return this.currentStepConfig.hideSectionTitle;
    },
    currentStepSubtitle() {
      return this.currentStepConfig.subtitle;
    },
    sectionName() {
      return this.currentSectionConfig.sectionName;
    },
    currentStepComponent() {
      const { moduleDirectory: customModuleDirectory } = this.currentSectionConfig;
      const defaultModuleDirectory = QUESTIONNAIRE_DEFAULT_MODULE_DIRECTORY[this.questionnaireType];
      const moduleDirectory = customModuleDirectory || defaultModuleDirectory;

      const sectionDirectory = kebabCase(this.currentSectionId);
      const stepDirectory = kebabCase(this.currentStepId);
      const stepFileName = `${pascalCase(this.currentStepId)}.vue`;

      return () =>
        import(
          `@/modules/questionnaire/components/steps/${moduleDirectory}/${sectionDirectory}/${stepDirectory}/${stepFileName}`
        );
    },
    isPreviousStepVisible() {
      const previousStep = this.getPreviousStep();

      if (!previousStep) {
        return false;
      }

      return (
        this.isPathValid(previousStep) &&
        this.context.firstStep &&
        this.context.firstStep !== this.context.currentStep
      );
    },
    isNextStepVisible() {
      return this.isFinalStep || this.currentStepConfig.next !== undefined;
    },
    isFinalStep() {
      return this.currentStepId === FINAL_SECTION_ID;
    },
    progress() {
      return (this.context.answeredSteps * 100) / this.context.totalSteps;
    },
    timeLeft() {
      const total = TIME_TO_COMPLETE_THE_QUESTIONNAIRE_MINUTES_MAP[this.questionnaireType];
      return Math.ceil(total - total * (this.progress / 100));
    },
    hasWhyWeAskSection() {
      return !this.isPhotoAnalysis && !!this.dysfunction;
    }
  },
  created() {
    if (this.questionnaireType === QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS) {
      return;
    }

    this.timer = new IdlePageTimer(this.onPageTimeout, IDLE_TIMEOUT);

    window.addEventListener('popstate', this.onPopState);
  },
  beforeDestroy() {
    this.timer.stop();

    window.removeEventListener('popstate', this.onPopState);
  },
  methods: {
    ...mapActions({
      setAcceptConsent: questTypes.actions.SET_IS_CONSENTS_ACCEPTED,
      setCurrentStep: questTypes.actions.SET_CURRENT_STEP,
      setAnsweredSteps: questTypes.actions.SET_ANSWERED_STEPS,
      updatePatient: questTypes.actions.UPDATE_PATIENT,
      updateQuestionnaire: questTypes.actions.UPDATE_QUESTIONNAIRE,
      fetchScores: questTypes.actions.FETCH_SCORES,
      sendCongratulations: questTypes.actions.SEND_CONGRATULATIONS,
      sendIncompleteQuestionnaire: questTypes.actions.SEND_INCOMPLETE_QUESTIONNAIRE,
      setLoading: rootTypes.actions.SET_LOADING,
      setLocale: rootTypes.actions.SET_LOCALE
    }),
    async onLanguageSelect(locale) {
      await this.updatePatient({ locale });
      this.setLocale(locale);
    },
    resolveStep(direction) {
      return typeof direction === 'function'
        ? direction(this.questionnaireFields, this.questionnaire)
        : direction;
    },
    getNextStep() {
      return this.resolveStep(this.currentStepConfig.next);
    },
    getPreviousStep() {
      return this.resolveStep(this.currentStepConfig.previous);
    },
    isPathValid(path) {
      const [nextSection, nextStep] = path.split('/');

      return has(this.config, nextSection) && has(this.config[nextSection].steps, nextStep);
    },
    async canContinue() {
      const func = this.currentStepConfig.onNext;

      if (func && typeof func === 'function') {
        return func(this.$store, this.questionnaireFields);
      }

      return true;
    },
    showPreviousStep() {
      const previous = this.getPreviousStep();

      if (previous && this.isPathValid(previous)) {
        this.showStep(previous);

        this.updateHistory();
      }
    },
    setStepValid(isValid) {
      this.isStepValid = isValid;
    },
    async validateStep() {
      const { stepComponent } = this.$refs;

      const isValid = await stepComponent.validateFields();

      if (!isValid) {
        this.setStepValid(false);

        return false;
      }

      this.setStepValid(true);

      return true;
    },
    async onNextStep() {
      this.isLoading = true;
      await this.showNextStep();
    },
    async showNextStep() {
      const nextStepHandler = async () => {
        const isValid = await this.validateStep();

        if (!isValid) {
          this.isLoading = false;
          return;
        }

        const next = await this.getNextStep();

        if (!next) {
          this.setLoading(true);

          const isQuestionnaireSubmited = await this.submitQuestionnaire();

          if (isQuestionnaireSubmited) {
            await this.$router.push('/q/congratulations');
          }

          this.setLoading(false);
        } else if (this.isPathValid(next)) {
          const canContinue = await this.canContinue();

          if (canContinue) {
            this.showStep(next);
            this.updateHistory();
          }
        }

        this.isLoading = false;
      };

      const debouncedHandler = debounce(
        (resolve, reject) => {
          nextStepHandler()
            .then(resolve)
            .catch(reject);
        },
        NEXT_PAGE_DELAY,
        { maxWait: NEXT_PAGE_DELAY }
      );

      return new Promise((resolve, reject) => {
        debouncedHandler(resolve, reject);
      });
    },
    showStep(step) {
      const currentIndex = this.currentStepConfig.index;

      this.setCurrentStep(step);

      this.setAnsweredSteps(
        this.context.answeredSteps + this.currentStepConfig.index - currentIndex
      );
    },
    updateHistory() {
      window.scrollTo(0, 0);
      history.pushState(null, '');
    },
    onPopState() {
      this.showPreviousStep();
    },
    async onPageTimeout() {
      this.$modal.show('questionnaire-time-limit-exceed-modal', {
        patientEmail: this.patient.email
      });

      await this.sendIncompleteQuestionnaire();
      await this.updateQuestionnaire({ complete: false });

      clearLocalStorage();
    },
    onQuestionnaireIdle() {
      redirectPatientAfterQuestionnaire(this.$i18n, this.doctor);
    },
    async submitQuestionnaire() {
      window.scrollTo(0, 0);

      const patientResponse = await this.updatePatient();

      if (hasResponseErrors(patientResponse)) {
        return false;
      }

      const questionnaireResponse = await this.updateQuestionnaire({ complete: true });

      if (hasResponseErrors(questionnaireResponse)) {
        return false;
      }

      const scoresResponse = await this.fetchScores();

      if (hasResponseErrors(scoresResponse)) {
        return false;
      }

      const congratsResponse = await this.sendCongratulations();

      return !hasResponseErrors(congratsResponse);
    }
  },
  metaInfo: {
    title: 'Questionnaire',
    titleTemplate: 'skinXs - %s'
  }
};
</script>
