import { equalsAny, yes } from '@/modules/questionnaire/api/helpers';

import { PHOTOTYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

export default {
  skinDysfunctions: {
    index: 20,
    heading: 'skinDysfunctions.label',
    contentBaseWidth: 572,
    noDivider: true,
    previous({ hyperpigmentation, phototype }) {
      if (equalsAny(phototype, PHOTOTYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return yes(hyperpigmentation) ? 'skinPigmentation/type' : 'skinPigmentation/pigmentation';
    },
    next(fields, { skippedSections }) {
      return skippedSections.includes(SKIPPED_SECTIONS.MEDICAL_BACKGROUND_SECTION)
        ? 'myDoctor/myDoctor'
        : 'medicalBackground/oneLastStep';
    }
  }
};
