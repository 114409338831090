import { TEMPERATURE } from '@/modules/questionnaire/api/constants';
import { all, equalsAny, yes } from '@/modules/questionnaire/api/helpers';

export default {
  dryness: {
    // P(72)
    index: 75,
    heading: 'heading.mySkinAndDryness',
    previous({ flushes, skinRedness, prominentBloodVessels }) {
      if (all(flushes, skinRedness)) {
        return 'skinRedness/swelling';
      }
      if (yes(prominentBloodVessels)) {
        return 'skinRedness/capillaryLocations';
      }

      return 'skinRedness/capillaries';
    },
    next({ skinDryness }) {
      return yes(skinDryness) ? 'skinDryness/drynessLocation' : 'skinOiliness/oiliness';
    }
  },

  drynessLocation: {
    // P(73)
    index: 76,
    heading: 'heading.mySkinAndDryness',
    previous: 'skinDryness/dryness',
    next({ temperature }) {
      return equalsAny(temperature, [
        TEMPERATURE.FREEZING,
        TEMPERATURE.VERY_COLD,
        TEMPERATURE.COLD,
        TEMPERATURE.COOL
      ])
        ? 'skinDryness/environment'
        : 'skinDryness/dehydration';
    },
    visible({ skinDryness }) {
      return yes(skinDryness);
    }
  },

  environment: {
    // P(74)
    index: 77,
    heading: 'heading.mySkinDrynessAndEnvironment',
    previous: 'skinDryness/drynessLocation',
    next: 'skinDryness/dehydration',
    visible({ skinDryness, temperature }) {
      return (
        yes(skinDryness) &&
        equalsAny(temperature, [
          TEMPERATURE.FREEZING,
          TEMPERATURE.VERY_COLD,
          TEMPERATURE.COLD,
          TEMPERATURE.COOL
        ])
      );
    }
  },

  dehydration: {
    // P(75)
    index: 78,
    heading: 'heading.mySkinDrynessAndDehydration',
    previous({ temperature }) {
      return equalsAny(temperature, [
        TEMPERATURE.FREEZING,
        TEMPERATURE.VERY_COLD,
        TEMPERATURE.COLD,
        TEMPERATURE.COOL
      ])
        ? 'skinDryness/environment'
        : 'skinDryness/drynessLocation';
    },
    next: 'skinOiliness/oiliness',
    visible({ skinDryness }) {
      return yes(skinDryness);
    }
  }
};
