<template>
  <questionnaire-base-modal
    :title="$t('validationMessage.title.checkYourEmail')"
    name="questionnaire-time-limit-exceed-modal"
    @before-open="onBeforeOpen"
  >
    <template #main-content>
      <i18n class="time-limit-exceed-modal__content" tag="p" path="info.warning.timeOut">
        <b>{{ patientEmail }}</b>
      </i18n>
    </template>

    <template #actions>
      <u-button class="time-limit-exceed-modal__close-button" kind="primary" @click="onClose">{{
        $t('action.close')
      }}</u-button>
    </template>
  </questionnaire-base-modal>
</template>

<script>
import { UButton } from 'universkin-shared';

import { isMobileDevice } from '@/modules/dashboard/utils';

import QuestionnaireBaseModal from '@/modules/questionnaire/components/dialogs/QuestionnaireBaseModal';

export default {
  name: 'DialogIdleModal',
  components: { UButton, QuestionnaireBaseModal },
  data() {
    return {
      patientEmail: ''
    };
  },
  computed: {
    modalMaxWidth() {
      return isMobileDevice() ? 328 : 550;
    }
  },
  methods: {
    onBeforeOpen({ params: { patientEmail } }) {
      this.patientEmail = patientEmail;
    },
    onClose() {
      this.$emit('idle');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';

.time-limit-exceed-modal {
  &__content {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-graphite;
    letter-spacing: 0.4px;
    text-align: center;
  }

  &__close-button {
    margin: 0 auto;
  }
}

@media (min-width: $desktop-start) {
  .time-limit-exceed-modal {
    &__content {
      font-size: 16px;
      line-height: 32px;
    }
  }
}
</style>
