import photoAnalysisResults from '@/modules/questionnaire/api/config/photo-analysis/sections/photoAnalysisResults';
import oxidativeStress from '@/modules/questionnaire/api/config/photo-analysis/sections/oxidativeStress';
import skinAppearance from '@/modules/questionnaire/api/config/photo-analysis/sections/skinAppearance';
import skinRedness from '@/modules/questionnaire/api/config/photo-analysis/sections/skinRedness';
import skinDryness from '@/modules/questionnaire/api/config/photo-analysis/sections/skinDryness';
import skinOiliness from '@/modules/questionnaire/api/config/photo-analysis/sections/skinOiliness';
import skinTexture from '@/modules/questionnaire/api/config/photo-analysis/sections/skinTexture';
import pimples from '@/modules/questionnaire/api/config/photo-analysis/sections/pimples';
import skinPigmentation from '@/modules/questionnaire/api/config/photo-analysis/sections/skinPigmentation';
import skinDysfunctions from '@/modules/questionnaire/api/config/photo-analysis/sections/skinDysfunctions';
import medicalBackground from '@/modules/questionnaire/api/config/photo-analysis/sections/medicalBackground';
import myDoctor from '@/modules/questionnaire/api/config/photo-analysis/sections/myDoctor';
import identity from '@/modules/questionnaire/api/config/photo-analysis/sections/identity';

export default {
  photoAnalysisResults: {
    key: 'photoAnalysisResults',
    steps: photoAnalysisResults
  },
  oxidativeStress: {
    key: 'oxidativeStress',
    steps: oxidativeStress,
    sectionName: 'oxidative-stress',
    dysfunction: 'oxidative-stress'
  },
  skinAppearance: {
    key: 'skinAppearance',
    steps: skinAppearance,
    sectionName: 'skin-appearance',
    dysfunction: 'skin-appearance'
  },
  skinRedness: {
    key: 'skinRedness',
    steps: skinRedness,
    sectionName: 'skin-redness',
    dysfunction: 'skin-redness'
  },
  skinDryness: {
    key: 'skinDryness',
    steps: skinDryness,
    sectionName: 'skin-dryness',
    dysfunction: 'skin-dryness'
  },
  skinOiliness: {
    key: 'skinOiliness',
    steps: skinOiliness,
    sectionName: 'skin-oiliness',
    dysfunction: 'skin-oiliness'
  },
  skinTexture: {
    key: 'skinTexture',
    steps: skinTexture,
    sectionName: 'skin-texture',
    dysfunction: 'skin-texture'
  },
  pimples: {
    key: 'pimples',
    steps: pimples,
    sectionName: 'pimples',
    dysfunction: 'pimples'
  },
  skinPigmentation: {
    key: 'skinPigmentation',
    steps: skinPigmentation,
    sectionName: 'skin-pigmentation',
    dysfunction: 'skin-pigmentation'
  },
  skinDysfunctions: {
    key: 'skinDysfunctions',
    steps: skinDysfunctions,
    moduleDirectory: 'common'
  },
  medicalBackground: {
    key: 'medicalBackground',
    steps: medicalBackground,
    moduleDirectory: 'common',
    sectionName: 'medical-background'
  },
  myDoctor: {
    key: 'myDoctor',
    steps: myDoctor,
    moduleDirectory: 'common',
    sectionName: 'medical-background'
  },
  identity: {
    key: 'identity',
    steps: identity,
    moduleDirectory: 'common'
  }
};
