import { yes } from '@/modules/questionnaire/api/helpers';

export default {
  oiliness: {
    // P(77)
    index: 81,
    heading: 'heading.mySkinAndOiliness',
    previous({ skinDryness }) {
      return yes(skinDryness) ? 'skinDryness/dehydration' : 'skinDryness/dryness';
    },
    next({ greasySkin }) {
      return yes(greasySkin) ? 'skinOiliness/oilinessLocation' : 'skinTexture/roughness';
    }
  },

  oilinessLocation: {
    // P(78)
    index: 82,
    heading: 'heading.mySkinAndOiliness',
    previous: 'skinOiliness/oiliness',
    next: 'skinOiliness/environment',
    visible({ greasySkin }) {
      return yes(greasySkin);
    }
  },

  environment: {
    // P(79)
    index: 83,
    heading: 'heading.mySkinOilinessAndEnvironment',
    previous: 'skinOiliness/oilinessLocation',
    next: 'skinTexture/roughness',
    visible({ greasySkin }) {
      return yes(greasySkin);
    }
  }
};
