import { reverse } from 'lodash';

import {
  equalsAny,
  female,
  includes,
  isAnySkinDysfunctions,
  other,
  yes
} from '@/modules/questionnaire/api/helpers';

import {
  ALLERGY,
  PHOTOTYPES_TO_LIGHTEN,
  PHOTOTYPES_TO_WOUND_HEALING,
  SUBSTANCE_ALLERGY
} from '@/modules/questionnaire/api/constants';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

// Helper - Allergy Types

const ORDERED_ALLERGIES = [
  ALLERGY.FOOD,
  ALLERGY.MEDICATION,
  ALLERGY.ENVIRONMENTAL,
  ALLERGY.SUBSTANCE
];

const getNextAllergyType = (allergies, selectedAllergies, start) => {
  const startAllergyIndex = start ? allergies.indexOf(start) : 0;

  const foundAllergy = allergies
    .slice(startAllergyIndex)
    .find(allergy => selectedAllergies.includes(allergy));

  return foundAllergy ? `medicalBackground/${foundAllergy}Allergies` : undefined;
};

const REVERSE_ALLERGIES = reverse(ORDERED_ALLERGIES.slice());

const getNextAllergyTypePage = (allergyTypes, start) =>
  getNextAllergyType(ORDERED_ALLERGIES, allergyTypes, start) || 'medicalBackground/exfoliants';

const getPreviousAllergyTypePage = (allergyTypes, start) =>
  getNextAllergyType(REVERSE_ALLERGIES, allergyTypes, start) || 'medicalBackground/allergies';

export default {
  oneLastStep: {
    index: 21,
    hideFooter: true,
    contentCentered: true,
    hideSectionTitle: true,
    contentBaseWidth: 753,
    noDivider: true,
    previous(fields, { skippedSections }) {
      if (skippedSections.includes(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS)) {
        return 'photoAnalysisResults/improveYourResults';
      }

      if (isAnySkinDysfunctions(fields)) {
        return 'skinDysfunctions/skinDysfunctions';
      }

      const { phototype, hyperpigmentation } = fields;

      if (equalsAny(phototype, PHOTOTYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return yes(hyperpigmentation) ? 'skinPigmentation/type' : 'skinPigmentation/pigmentation';
    },
    next({ gender, age, proneToAcne }) {
      if (female(gender) && age >= 15) {
        return 'medicalBackground/gynecoHistory';
      }

      if (other(gender)) {
        return 'medicalBackground/hormonalHistory';
      }

      return yes(proneToAcne) ? 'medicalBackground/breakoutHistory' : 'medicalBackground/allergies';
    }
  },
  gynecoHistory: {
    index: 22,
    heading: 'heading.myGynecoHistory',
    previous: 'medicalBackground/oneLastStep',
    next({ proneToAcne }) {
      return yes(proneToAcne) ? 'medicalBackground/breakoutHistory' : 'medicalBackground/allergies';
    }
  },
  hormonalHistory: {
    index: 23,
    heading: 'heading.myHormonalHistory',
    previous: 'medicalBackground/oneLastStep',
    next({ proneToAcne }) {
      return yes(proneToAcne) ? 'medicalBackground/breakoutHistory' : 'medicalBackground/allergies';
    }
  },
  breakoutHistory: {
    index: 24,
    heading: 'heading.myBreakoutHistory',
    previous({ gender, age }) {
      if (other(gender)) {
        return 'medicalBackground/hormonalHistory';
      }

      if (female(gender) && age >= 15) {
        return 'medicalBackground/gynecoHistory';
      }

      return 'medicalBackground/oneLastStep';
    },
    next: 'medicalBackground/allergies'
  },
  allergies: {
    index: 25,
    heading: 'heading.myAllergenicHistory',
    previous({ proneToAcne, gender, age }) {
      if (yes(proneToAcne)) {
        return 'medicalBackground/breakoutHistory';
      }

      if (other(gender)) {
        return 'medicalBackground/hormonalHistory';
      }

      return female(gender) && age >= 15
        ? 'medicalBackground/gynecoHistory'
        : 'medicalBackground/oneLastStep';
    },
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes);
    }
  },
  foodAllergies: {
    index: 26,
    heading: 'heading.myAllergenicHistory',
    previous: 'medicalBackground/allergies',
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.MEDICATION);
    }
  },
  medicationAllergies: {
    index: 27,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.FOOD);
    },
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.ENVIRONMENTAL);
    }
  },
  environmentalAllergies: {
    index: 28,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.MEDICATION);
    },
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.SUBSTANCE);
    }
  },
  substanceAllergies: {
    index: 29,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.ENVIRONMENTAL);
    },
    next({ substanceAllergies }) {
      if (includes(substanceAllergies, SUBSTANCE_ALLERGY.COSMETICS)) {
        return 'medicalBackground/cosmeticAllergies';
      }

      return 'medicalBackground/exfoliants';
    }
  },
  cosmeticAllergies: {
    index: 30,
    heading: 'heading.myAllergenicHistory',
    previous: 'medicalBackground/substanceAllergies',
    next: 'medicalBackground/exfoliants'
  },
  exfoliants: {
    index: 31,
    heading: 'heading.mySkinAndExfoliants',
    previous({ allergyTypes, substanceAllergies }) {
      if (includes(substanceAllergies, SUBSTANCE_ALLERGY.COSMETICS)) {
        return 'medicalBackground/cosmeticAllergies';
      }

      return getPreviousAllergyTypePage(allergyTypes);
    },
    next({ phototype }) {
      return equalsAny(phototype, PHOTOTYPES_TO_WOUND_HEALING)
        ? 'medicalBackground/woundHealing'
        : 'medicalBackground/dermatoHistory';
    }
  },
  woundHealing: {
    index: 32,
    heading: 'heading.mySkinAndWoundHealing',
    previous: 'medicalBackground/exfoliants',
    next: 'medicalBackground/dermatoHistory'
  },
  dermatoHistory: {
    index: 33,
    heading: 'heading.myDermatoHistory',
    previous({ phototype }) {
      return equalsAny(phototype, PHOTOTYPES_TO_WOUND_HEALING)
        ? 'medicalBackground/woundHealing'
        : 'medicalBackground/exfoliants';
    },
    next: 'medicalBackground/medicalHistory'
  },
  medicalHistory: {
    index: 34,
    heading: 'heading.myMedicalHistory',
    previous: 'medicalBackground/dermatoHistory',
    next: 'myDoctor/myDoctor'
  }
};
