import { equalsAny, isAnySkinDysfunctions, yes } from '@/modules/questionnaire/api/helpers';

import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';
import { PHOTOTYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';

const getDysfunctionsEndStep = (fields, { skippedSections }) => {
  if (isAnySkinDysfunctions(fields)) {
    return 'skinDysfunctions/skinDysfunctions';
  }

  return skippedSections.includes(SKIPPED_SECTIONS.MEDICAL_BACKGROUND_SECTION)
    ? 'myDoctor/myDoctor'
    : 'medicalBackground/oneLastStep';
};

const getLightningNextStep = (fields, questionnaire) => {
  if (equalsAny(fields.phototype, PHOTOTYPES_TO_LIGHTEN)) {
    return 'skinPigmentation/lightning';
  }

  return getDysfunctionsEndStep(fields, questionnaire);
};

export default {
  pigmentation: {
    index: 17,
    heading: 'heading.mySkinAndPigmentation',
    previous({ proneToAcne }) {
      return yes(proneToAcne) ? 'pimples/type' : 'pimples/pimples';
    },
    next(fields, questionnaire) {
      const { hyperpigmentation } = fields;

      if (yes(hyperpigmentation)) {
        return 'skinPigmentation/type';
      }

      return getLightningNextStep(fields, questionnaire);
    },
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.brown;
      const { brownScorePhrase, brownText } = introTexts;

      const message = `${$t(brownScorePhrase, [score])} ${$t(brownText)}`;

      return { message, score };
    }
  },
  type: {
    index: 18,
    heading: 'heading.mySkinAndPigmentation',
    previous: 'skinPigmentation/pigmentation',
    next(fields, questionnaire) {
      return getLightningNextStep(fields, questionnaire);
    },
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.brown;
      const { brownScorePhrase, brownText } = introTexts;

      const message = `${$t(brownScorePhrase, [score])} ${$t(brownText)}`;

      return { message, score };
    }
  },
  lightning: {
    index: 19,
    heading: 'heading.mySkinLightening',
    previous({ hyperpigmentation }) {
      return yes(hyperpigmentation) ? 'skinPigmentation/type' : 'skinPigmentation/pigmentation';
    },
    next(fields, questionnaire) {
      return getDysfunctionsEndStep(fields, questionnaire);
    }
  }
};
