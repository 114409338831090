import { AMOUNT } from '@/modules/questionnaire/api/constants';
import { body, onlyFace, yes } from '@/modules/questionnaire/api/helpers';

export default {
  pimples: {
    // P(89)
    index: 93,
    heading: 'heading.mySkinAndPimples',
    previous({ patches, comedones, skinRoughness }) {
      if (skinRoughness === AMOUNT.CONSIDERABLY || skinRoughness === AMOUNT.MODERATELY) {
        return 'skinTexture/patches';
      }

      if (yes(patches)) {
        return 'skinTexture/patchLocations';
      }

      if (yes(comedones)) {
        return 'skinTexture/bumpsLocation';
      }

      return 'skinTexture/bumps';
    },
    next({ soughtTreatments, proneToAcne }) {
      if (yes(proneToAcne)) {
        return onlyFace(soughtTreatments) ? 'pimples/coverage' : 'pimples/locations';
      }

      return 'skinPigmentation/pigmentation';
    }
  },

  coverage: {
    // P(90)
    index: 94,
    heading: 'heading.myPimples',
    previous: 'pimples/pimples',
    next: 'pimples/type',
    visible({ soughtTreatments, proneToAcne }) {
      return onlyFace(soughtTreatments) && yes(proneToAcne);
    }
  },

  locations: {
    // P(91)
    index: 95,
    heading: 'heading.myPimples',
    previous: 'pimples/pimples',
    next: 'pimples/type',
    visible({ soughtTreatments, proneToAcne }) {
      return body(soughtTreatments) && yes(proneToAcne);
    }
  },

  type: {
    // P(92)
    index: 96,
    heading: 'heading.myPimples',
    previous({ soughtTreatments }) {
      return onlyFace(soughtTreatments) ? 'pimples/coverage' : 'pimples/locations';
    },
    next: 'pimples/persistence',
    visible({ proneToAcne }) {
      return yes(proneToAcne);
    }
  },

  persistence: {
    // P(93)
    index: 97,
    heading: 'heading.myPimpleFrequency',
    previous: 'pimples/type',
    next: 'skinPigmentation/pigmentation',
    visible({ proneToAcne }) {
      return yes(proneToAcne);
    }
  }
};
