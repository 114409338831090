import { equalsAny, isAnySkinDysfunctions, yes } from '@/modules/questionnaire/api/helpers';

import { PHOTOTYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';
import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

export default {
  myDoctor: {
    index: 35,
    heading: 'heading.myDoctor',
    previous(fields, { skippedSections }) {
      if (!skippedSections.includes(SKIPPED_SECTIONS.MEDICAL_BACKGROUND_SECTION)) {
        return 'medicalBackground/medicalHistory';
      }

      if (skippedSections.includes(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS)) {
        return 'photoAnalysisResults/improveYourResults';
      }

      if (isAnySkinDysfunctions(fields)) {
        return 'skinDysfunctions/skinDysfunctions';
      }

      const { phototype, hyperpigmentation } = fields;

      if (equalsAny(phototype, PHOTOTYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return yes(hyperpigmentation) ? 'skinPigmentation/type' : 'skinPigmentation/pigmentation';
    },
    next: 'identity/identity'
  }
};
