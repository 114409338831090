import identitySteps from '@/modules/questionnaire/api/config/questionnaire/sections/identity';
import medicalBackgroundSteps from '@/modules/questionnaire/api/config/questionnaire/sections/medicalBackground';
import myDoctorSteps from '@/modules/questionnaire/api/config/questionnaire/sections/myDoctor';
import oxidativeStressSteps from '@/modules/questionnaire/api/config/questionnaire/sections/oxidativeStress';
import pimplesSteps from '@/modules/questionnaire/api/config/questionnaire/sections/pimples';
import skinAppearanceSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinAppearance';
import skinDrynessSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinDryness';
import skinDysfunctionsSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinDysfunctions';
import skinOilinessSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinOiliness';
import skinPigmentationSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinPigmentation';
import skinRednessSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinRedness';
import skinTextureSteps from '@/modules/questionnaire/api/config/questionnaire/sections/skinTexture';

import { faceOrBody } from '@/modules/questionnaire/api/helpers';

export default {
  identity: {
    key: 'identity',
    steps: identitySteps,
    moduleDirectory: 'common',
    sectionName: 'medical-background'
  },
  medicalBackground: {
    key: 'medicalBackground',
    steps: medicalBackgroundSteps,
    moduleDirectory: 'common',
    sectionName: 'medical-background'
  },
  oxidativeStress: {
    key: 'oxidativeStress',
    steps: oxidativeStressSteps,
    sectionName: 'oxidative-stress',
    dysfunction: 'oxidative-stress'
  },
  skinAppearance: {
    key: 'skinAppearance',
    steps: skinAppearanceSteps,
    sectionName: 'skin-appearance',
    dysfunction: 'skin-appearance'
  },
  skinRedness: {
    key: 'skinRedness',
    steps: skinRednessSteps,
    sectionName: 'skin-redness',
    dysfunction: 'skin-redness',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  skinDryness: {
    key: 'skinDryness',
    steps: skinDrynessSteps,
    sectionName: 'skin-dryness',
    dysfunction: 'skin-dryness',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  skinOiliness: {
    key: 'skinOiliness',
    steps: skinOilinessSteps,
    sectionName: 'skin-oiliness',
    dysfunction: 'skin-oiliness',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  skinTexture: {
    key: 'skinTexture',
    steps: skinTextureSteps,
    sectionName: 'skin-texture',
    dysfunction: 'skin-texture',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  pimples: {
    key: 'pimples',
    steps: pimplesSteps,
    sectionName: 'pimples',
    dysfunction: 'pimples',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  skinPigmentation: {
    key: 'skinPigmentation',
    steps: skinPigmentationSteps,
    sectionName: 'skin-pigmentation',
    dysfunction: 'skin-pigmentation',
    visible({ soughtTreatments }) {
      return faceOrBody(soughtTreatments);
    }
  },
  skinDysfunctions: {
    key: 'skinDysfunctions',
    steps: skinDysfunctionsSteps,
    moduleDirectory: 'common'
  },
  myDoctor: {
    key: 'myDoctor',
    steps: myDoctorSteps,
    moduleDirectory: 'common',
    sectionName: 'medical-background'
  }
};
