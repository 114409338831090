import { body, equalsAny, face, yes } from '@/modules/questionnaire/api/helpers';

import { SKIN_TYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';

export default {
  skinDysfunctions: {
    index: 107,
    heading: 'skinDysfunctions.label',
    contentBaseWidth: 572,
    noDivider: true,
    previous({ hyperpigmentation, soughtTreatments, skinType }) {
      switch (true) {
        case equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN): {
          return 'skinPigmentation/lightning';
        }

        case yes(hyperpigmentation) && face(soughtTreatments): {
          return 'skinPigmentation/face';
        }

        case yes(hyperpigmentation) && body(soughtTreatments): {
          return 'skinPigmentation/locations';
        }

        default: {
          return 'skinPigmentation/pigmentation';
        }
      }
    },
    next: 'myDoctor/myDoctor'
  }
};
