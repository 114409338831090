import {
  body,
  equalsAny,
  face,
  isAnySkinDysfunctions,
  no,
  yes
} from '@/modules/questionnaire/api/helpers';

import { SKIN_TYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';

export default {
  pigmentation: {
    // P(95)
    index: 100,
    heading: 'heading.mySkinAndPigmentation',
    previous({ proneToAcne }) {
      return yes(proneToAcne) ? 'pimples/persistence' : 'pimples/pimples';
    },
    next(fields) {
      const { hyperpigmentation, skinType } = fields;

      if (yes(hyperpigmentation)) {
        return 'skinPigmentation/type';
      }

      if (equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return isAnySkinDysfunctions(fields)
        ? 'skinDysfunctions/skinDysfunctions'
        : 'myDoctor/myDoctor';
    }
  },

  type: {
    // P(96)
    index: 101,
    heading: 'heading.mySkinAndPigmentationType',
    previous: 'skinPigmentation/pigmentation',
    next({ soughtTreatments }) {
      return body(soughtTreatments) ? 'skinPigmentation/locations' : 'skinPigmentation/face';
    },
    visible({ hyperpigmentation }) {
      return yes(hyperpigmentation);
    }
  },

  locations: {
    // P(97)
    index: 102,
    heading: 'heading.mySkinAndPigmentation',
    previous: 'skinPigmentation/type',
    next(fields) {
      const { soughtTreatments, skinType } = fields;

      if (face(soughtTreatments)) {
        return 'skinPigmentation/face';
      }

      if (equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return isAnySkinDysfunctions(fields)
        ? 'skinDysfunctions/skinDysfunctions'
        : 'myDoctor/myDoctor';
    },
    visible({ hyperpigmentation, soughtTreatment }) {
      return yes(hyperpigmentation) && body(soughtTreatment);
    }
  },

  face: {
    // P(98)
    index: 103,
    heading: 'heading.mySkinAndPigmentation',
    previous({ soughtTreatments }) {
      return body(soughtTreatments) ? 'skinPigmentation/locations' : 'skinPigmentation/type';
    },
    next(fields) {
      const { skinType } = fields;

      if (equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      return isAnySkinDysfunctions(fields)
        ? 'skinDysfunctions/skinDysfunctions'
        : 'myDoctor/myDoctor';
    },
    visible({ hyperpigmentation, soughtTreatment }) {
      return yes(hyperpigmentation) && face(soughtTreatment);
    }
  },

  lightning: {
    // P(99)
    index: 104,
    heading: 'heading.mySkinLightening',
    previous({ hyperpigmentation, soughtTreatments }) {
      // 177, 16, 180
      if (no(hyperpigmentation)) {
        return 'skinPigmentation/pigmentation';
      }

      if (face(soughtTreatments)) {
        return 'skinPigmentation/face';
      }

      return 'skinPigmentation/locations';
    },
    next(fields) {
      return isAnySkinDysfunctions(fields)
        ? 'skinDysfunctions/skinDysfunctions'
        : 'myDoctor/myDoctor';
    },
    visible({ skinType }) {
      return equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN);
    }
  }
};
