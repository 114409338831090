import { yes } from '@/modules/questionnaire/api/helpers';

export default {
  pimples: {
    index: 15,
    heading: 'heading.mySkinAndPimples',
    previous: 'skinTexture/bumps',
    next({ proneToAcne }) {
      return yes(proneToAcne) ? 'pimples/type' : 'skinPigmentation/pigmentation';
    },
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.green;
      const { greenScorePhrase, greenText } = introTexts;

      const message = `${$t(greenScorePhrase, [score])} ${$t(greenText)}`;

      return { message, score };
    }
  },
  type: {
    index: 16,
    heading: 'heading.myPimples',
    previous: 'pimples/pimples',
    next: 'skinPigmentation/pigmentation',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.green;
      const { greenScorePhrase, greenText } = introTexts;

      const message = `${$t(greenScorePhrase, [score])} ${$t(greenText)}`;

      return { message, score };
    }
  }
};
