import { yes } from '@/modules/questionnaire/api/helpers';

export default {
  oiliness: {
    index: 11,
    heading: 'heading.mySkinAndOiliness',
    previous: 'skinDryness/dryness',
    next({ greasySkin }) {
      return yes(greasySkin) ? 'skinOiliness/environment' : 'skinTexture/roughness';
    },
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.orange;
      const { orangeScorePhrase, orangeText } = introTexts;

      const message = `${$t(orangeScorePhrase, [score])} ${$t(orangeText)}`;

      return { message, score };
    }
  },
  environment: {
    index: 12,
    heading: 'heading.mySkinOilinessAndEnvironment',
    previous: 'skinOiliness/oiliness',
    next: 'skinTexture/roughness',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.orange;
      const { orangeScorePhrase, orangeText } = introTexts;

      const message = `${$t(orangeScorePhrase, [score])} ${$t(orangeText)}`;

      return { message, score };
    }
  }
};
