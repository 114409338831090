import { AMOUNT } from '@/modules/questionnaire/api/constants';
import { faceOrBody, yes } from '@/modules/questionnaire/api/helpers';

export default {
  roughness: {
    // P(80)
    index: 85,
    heading: 'heading.mySkinRoughness',
    previous({ greasySkin }) {
      return yes(greasySkin) ? 'skinOiliness/environment' : 'skinOiliness/oiliness';
    },
    next: 'skinTexture/bumps'
  },

  bumps: {
    // P(81)
    index: 86,
    heading: 'heading.mySkinAndCongestion',
    previous: 'skinTexture/roughness',
    next({ comedones, skinRoughness }) {
      if (yes(comedones)) {
        return 'skinTexture/bumpsLocation';
      }

      if (skinRoughness === AMOUNT.CONSIDERABLY || skinRoughness === AMOUNT.MODERATELY) {
        return 'skinTexture/patches';
      }

      return 'pimples/pimples';
    }
  },

  bumpsLocation: {
    // P(82)
    index: 87,
    heading: 'heading.mySkinAndCongestion',
    previous: 'skinTexture/bumps',

    visible({ comedones }) {
      return yes(comedones);
    },
    next({ skinRoughness }) {
      if (skinRoughness === AMOUNT.CONSIDERABLY || skinRoughness === AMOUNT.MODERATELY) {
        return 'skinTexture/patches';
      }

      return 'pimples/pimples';
    }
  },

  patches: {
    // P(85)
    index: 88,
    heading: 'heading.mySkinAndRedPatches',
    previous({ comedones }) {
      return yes(comedones) ? 'skinTexture/bumpsLocation' : 'skinTexture/bumps';
    },
    next({ patches, comedones }) {
      if (yes(patches)) {
        return 'skinTexture/patchCauses';
      }

      if (yes(comedones)) {
        return 'pimples/pimples';
      }

      return 'pimples/pimples';
    },
    visible({ soughtTreatment }) {
      return faceOrBody(soughtTreatment);
    }
  },

  patchCauses: {
    // P(86)
    index: 89,
    heading: 'heading.mySkinAndRedPatches',
    previous: 'skinTexture/patches',
    next: 'skinTexture/patchLocations',
    visible({ patches }) {
      return yes(patches);
    }
  },

  patchLocations: {
    // P(87)
    index: 90,
    heading: 'heading.mySkinPatchLocations',
    previous: 'skinTexture/patchCauses',
    next: 'pimples/pimples',
    visible({ patches }) {
      return yes(patches);
    }
  }
};
