export default {
  ageing: {
    index: 4,
    heading: 'heading.mySkinAndAgeing',
    previous: 'oxidativeStress/sun',
    next: 'skinAppearance/scars',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.pink;
      const { pinkScorePhrase, pinkText } = introTexts;

      const message = `${$t(pinkScorePhrase, [score])} ${$t(pinkText)}`;

      return { message, score };
    }
  },
  scars: {
    index: 5,
    heading: 'heading.mySkinAndScars',
    previous: 'skinAppearance/ageing',
    next: 'skinAppearance/darkCircles',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const { pinkTextAcne } = introTexts;
      const { pink } = photoAnalysisDiagnostic;

      return { message: $t(pinkTextAcne), score: pink };
    }
  },
  darkCircles: {
    index: 6,
    heading: 'heading.myEyeContour',
    previous: 'skinAppearance/scars',
    next: 'skinAppearance/bags',
    getScoringConfig: ($t, { introTexts: { pinkTextDarkCircles } }) => ({
      message: $t(pinkTextDarkCircles)
    })
  },
  bags: {
    index: 7,
    heading: 'heading.myEyeContour',
    previous: 'skinAppearance/darkCircles',
    next: 'skinRedness/flushing',
    getScoringConfig: ($t, { introTexts: { pinkTextEyeBags } }) => ({
      message: $t(pinkTextEyeBags)
    })
  }
};
