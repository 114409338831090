import { SKIPPED_SECTIONS } from '@/modules/questionnaire/constants/sections';

export default {
  preliminaryResults: {
    index: 1,
    heading: 'heading.preliminaryResults',
    basicBackground: true,
    subtitle: 'preliminaryResults.subtitle',
    next: 'photoAnalysisResults/improveYourResults',
    noDivider: true
  },
  improveYourResults: {
    index: 2,
    hideFooter: true,
    previous: 'photoAnalysisResults/preliminaryResults',
    contentBaseWidth: 753,
    contentCentered: true,
    noDivider: true,
    next(fields, { skippedSections }) {
      if (skippedSections.includes(SKIPPED_SECTIONS.DYSFUNCTIONS_SECTIONS)) {
        return skippedSections.includes(SKIPPED_SECTIONS.MEDICAL_BACKGROUND_SECTION)
          ? 'myDoctor/myDoctor'
          : 'medicalBackground/oneLastStep';
      }

      return 'oxidativeStress/sun';
    }
  }
};
