import { ALLERGY, SKIN_TYPE, SUBSTANCE_ALLERGY } from '@/modules/questionnaire/api/constants';
import { equalsAny, female, includes, other } from '@/modules/questionnaire/api/helpers';

import { reverse } from 'lodash';

// Helper - Allergy Types

const ORDERED_ALLERGIES = [
  ALLERGY.FOOD,
  ALLERGY.MEDICATION,
  ALLERGY.ENVIRONMENTAL,
  ALLERGY.SUBSTANCE
];

const REVERSE_ALLERGIES = reverse(ORDERED_ALLERGIES.slice());

const getNextAllergyType = (allergies, selectedAllergies, start) => {
  const startAllergyIndex = start ? allergies.indexOf(start) : 0;

  const foundAllergy = allergies
    .slice(startAllergyIndex)
    .find(allergy => selectedAllergies.includes(allergy));

  return foundAllergy ? `medicalBackground/${foundAllergy}Allergies` : undefined;
};

const getNextAllergyTypePage = (allergyTypes, start) =>
  getNextAllergyType(ORDERED_ALLERGIES, allergyTypes, start) || 'medicalBackground/exfoliants';

const getPreviousAllergyTypePage = (allergyTypes, start) =>
  getNextAllergyType(REVERSE_ALLERGIES, allergyTypes, start) || 'medicalBackground/allergies';

// Pages

export default {
  gynecoHistory: {
    // P(10)
    index: 9,
    heading: 'heading.myGynecoHistory',
    previous: 'identity/skinThickness',
    next: 'medicalBackground/breakoutHistory',
    visible({ gender, age }) {
      return female(gender) && age >= 15;
    }
  },

  hormonalHistory: {
    // P(11)
    index: 10,
    heading: 'heading.myHormonalHistory',
    previous: 'identity/skinThickness',
    next: 'medicalBackground/breakoutHistory',
    visible({ gender }) {
      return other(gender);
    }
  },

  breakoutHistory: {
    // P(12)
    index: 11,
    heading: 'heading.myBreakoutHistory',
    previous({ gender, age }) {
      if (female(gender) && age >= 15) {
        return 'medicalBackground/gynecoHistory';
      }
      if (other(gender)) {
        return 'medicalBackground/hormonalHistory';
      }

      return 'identity/skinThickness';
    },
    next: 'medicalBackground/allergies'
  },

  allergies: {
    // P(30)
    index: 29,
    heading: 'heading.myAllergenicHistory',
    previous: 'medicalBackground/breakoutHistory',
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes);
    }
  },

  foodAllergies: {
    // P(31)
    index: 30,
    heading: 'heading.myAllergenicHistory',
    previous: 'medicalBackground/allergies',
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.MEDICATION);
    },
    visible({ allergyTypes }) {
      return includes(allergyTypes, ALLERGY.FOOD);
    }
  },

  medicationAllergies: {
    // P(32)
    index: 31,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.FOOD);
    },
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.ENVIRONMENTAL);
    },
    visible({ allergyTypes }) {
      return includes(allergyTypes, ALLERGY.MEDICATION);
    }
  },

  environmentalAllergies: {
    // P(33)
    index: 32,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.MEDICATION);
    },
    next({ allergyTypes }) {
      return getNextAllergyTypePage(allergyTypes, ALLERGY.SUBSTANCE);
    },
    visible({ allergyTypes }) {
      return includes(allergyTypes, ALLERGY.ENVIRONMENTAL);
    }
  },

  substanceAllergies: {
    // P(34)
    index: 33,
    heading: 'heading.myAllergenicHistory',
    previous({ allergyTypes }) {
      return getPreviousAllergyTypePage(allergyTypes, ALLERGY.ENVIRONMENTAL);
    },
    next({ substanceAllergies }) {
      if (includes(substanceAllergies, SUBSTANCE_ALLERGY.COSMETICS)) {
        return 'medicalBackground/cosmeticAllergies';
      }

      return 'medicalBackground/exfoliants';
    },
    visible({ allergyTypes }) {
      return includes(allergyTypes, ALLERGY.SUBSTANCE);
    }
  },

  cosmeticAllergies: {
    // P(35)
    index: 34,
    heading: 'heading.myAllergenicHistory',
    previous: 'medicalBackground/substanceAllergies',
    next: 'medicalBackground/exfoliants',
    visible({ substanceAllergies }) {
      return includes(substanceAllergies, SUBSTANCE_ALLERGY.COSMETICS);
    }
  },

  exfoliants: {
    // P(36)
    index: 35,
    heading: 'heading.mySkinAndExfoliants',
    previous({ allergyTypes, substanceAllergies }) {
      if (includes(substanceAllergies, SUBSTANCE_ALLERGY.COSMETICS)) {
        return 'medicalBackground/cosmeticAllergies';
      }

      return getPreviousAllergyTypePage(allergyTypes);
    },
    next({ skinType }) {
      return equalsAny(skinType, [
        SKIN_TYPE.BURN_EASILY,
        SKIN_TYPE.BURN_MODERATELY,
        SKIN_TYPE.BURN_MINIMALLY,
        SKIN_TYPE.NEVER_BURN
      ])
        ? 'medicalBackground/woundHealing'
        : 'medicalBackground/dermatoHistory';
    }
  },

  woundHealing: {
    // P(37)
    index: 36,
    heading: 'heading.mySkinAndWoundHealing',
    previous: 'medicalBackground/exfoliants',
    next: 'medicalBackground/dermatoHistory'
  },

  dermatoHistory: {
    // P(20)
    index: 37,
    heading: 'heading.myDermatoHistory',
    previous({ skinType }) {
      return equalsAny(skinType, [
        SKIN_TYPE.BURN_EASILY,
        SKIN_TYPE.BURN_MODERATELY,
        SKIN_TYPE.BURN_MINIMALLY,
        SKIN_TYPE.NEVER_BURN
      ])
        ? 'medicalBackground/woundHealing'
        : 'medicalBackground/exfoliants';
    },
    next: 'medicalBackground/medicalHistory'
  },

  medicalHistory: {
    // P(38)
    index: 38,
    heading: 'heading.myMedicalHistory',
    previous: 'medicalBackground/dermatoHistory',
    next({ gender, age }) {
      return female(gender) && age > 16
        ? 'medicalBackground/skinSensitivity'
        : 'oxidativeStress/lifestyle1';
    }
  },

  skinSensitivity: {
    // P(39)
    index: 39,
    heading: 'heading.mySkinAndSensitivity',
    previous: 'medicalBackground/medicalHistory',
    next: 'oxidativeStress/lifestyle1'
  }
};
