import {
  body,
  equalsAny,
  face,
  isAnySkinDysfunctions,
  yes
} from '@/modules/questionnaire/api/helpers';

import { SKIN_TYPES_TO_LIGHTEN } from '@/modules/questionnaire/api/constants';

export default {
  myDoctor: {
    index: 108,
    heading: 'heading.myDoctor',
    previous(fields) {
      if (isAnySkinDysfunctions(fields)) {
        return 'skinDysfunctions/skinDysfunctions';
      }

      const { hyperpigmentation, soughtTreatments, skinType } = fields;

      if (equalsAny(skinType, SKIN_TYPES_TO_LIGHTEN)) {
        return 'skinPigmentation/lightning';
      }

      if (yes(hyperpigmentation) && face(soughtTreatments)) {
        return 'skinPigmentation/face';
      }

      if (yes(hyperpigmentation) && body(soughtTreatments)) {
        return 'skinPigmentation/locations';
      }

      return 'skinPigmentation/pigmentation';
    },
    next: 'identity/identity'
  }
};
