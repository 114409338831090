export default {
  flushing: {
    index: 8,
    heading: 'heading.mySkinAndFlushing',
    previous: 'skinAppearance/bags',
    next: 'skinRedness/capillaries',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.red;
      const { redScorePhrase, redText } = introTexts;

      const message = `${$t(redScorePhrase, [score])} ${$t(redText)}`;

      return { message, score };
    }
  },
  capillaries: {
    index: 9,
    heading: 'heading.mySkinAndCapillaries',
    previous: 'skinRedness/flushing',
    next: 'skinDryness/dryness',
    getScoringConfig: ($t, { introTexts: { redTextBloodVessels }, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.red;
      const message = $t(redTextBloodVessels);

      return { message, score };
    }
  }
};
