import { female, other } from '@/modules/questionnaire/api/helpers';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import { USAGE } from '@/modules/questionnaire/api/constants';
import rootTypes from '@/store/types';
import { types } from '@/modules/questionnaire/store/types';

export default {
  demographics: {
    index: 2,
    heading: 'heading.myDemographics',
    next: 'identity/facialFeatures',

    async onNext({ dispatch }) {
      await dispatch(rootTypes.actions.SET_LOADING, true);

      const patientResponse = await dispatch(types.actions.UPDATE_PATIENT);

      if (hasResponseErrors(patientResponse)) {
        await dispatch(rootTypes.actions.SET_LOADING, false);

        return false;
      }

      const questionnaireResponse = await dispatch(types.actions.UPDATE_QUESTIONNAIRE, {
        complete: false
      });

      await dispatch(rootTypes.actions.SET_LOADING, false);

      return !hasResponseErrors(questionnaireResponse);
    }
  },

  facialFeatures: {
    index: 3,
    heading: 'heading.myFacialFeatures',
    next: 'identity/skinColor',

    async onNext({ state, dispatch }) {
      const { questionnaire } = state;
      const { usage } = questionnaire.questionnaire.context;

      if (usage === USAGE.FIRST_QUESTIONNAIRE) {
        await dispatch(rootTypes.actions.SET_LOADING, true);

        await dispatch(types.actions.UPDATE_QUESTIONNAIRE, {
          complete: false
        });

        await dispatch(rootTypes.actions.SET_LOADING, false);
      }

      return true;
    }
  },

  skinColor: {
    index: 4,
    heading: 'heading.mySkinColor',
    previous: 'identity/facialFeatures',
    next: 'identity/phototype'
  },

  phototype: {
    index: 5,
    heading: 'heading.mySkinType',
    previous: 'identity/skinColor',
    next: 'identity/ethnicBackground'
  },

  ethnicBackground: {
    index: 6,
    heading: 'heading.myEthnicity',
    previous: 'identity/phototype',
    next: 'identity/skinThickness'
  },

  skinThickness: {
    index: 7,
    heading: 'heading.mySkinThickness',
    previous: 'identity/ethnicBackground',
    next({ gender, age }) {
      switch (true) {
        case female(gender) && age >= 15: {
          return 'medicalBackground/gynecoHistory';
        }

        case other(gender): {
          return 'medicalBackground/hormonalHistory';
        }

        default: {
          return 'medicalBackground/breakoutHistory';
        }
      }
    }
  },

  identity: {
    index: 109,
    noDivider: true,
    heading: 'heading.prefinalPage',
    previous: 'myDoctor/myDoctor'
  }
};
