export default {
  dryness: {
    index: 10,
    heading: 'heading.mySkinAndDryness',
    previous: 'skinRedness/capillaries',
    next: 'skinOiliness/oiliness',
    getScoringConfig: ($t, { introTexts, photoAnalysisDiagnostic }) => {
      const score = photoAnalysisDiagnostic.blue;
      const { blueScorePhrase, blueText } = introTexts;

      const message = `${$t(blueScorePhrase, [score])} ${$t(blueText)}`;

      return { message, score };
    }
  }
};
